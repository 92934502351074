import axios from "axios"
export default {
    data() {
        return {
            weburl: 'https://www.rayscloud.cn/webapi',
            data: {},
            menu: {},
            // menuId: 0
        }
    },
    updated() {
        let obj = document.getElementById('contant');
        let img = obj.getElementsByTagName('img');
        for (let i = 0; i < img.length; i++) {
            img[i].style.width = '100%'
        }
    },
    methods: {
        getdata(mid) {
            if (!mid) {
                mid = localStorage.getItem("menuId");
            }
            axios.get(`${this.weburl}/api/Cont/menList/${mid}`)
                .then((res) => {
                    // console.log(res)
                    if (res.status == 200) {
                        this.data = res.data.data[0];
                        // console.log(this.data)
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        },
    },
    created() {
        let mid = "";
        if (this.$route.query.mid) {
            mid = this.$route.query.mid;
        }
        this.getdata(mid);
    },
}