<template>
  <div class="about">
    <div class="container">
      <div class="banner">
        <div class="auto-container">
          <h1>{{ data.title }}</h1>
          <p>
            <a href="/">首页</a>
            <Icon
              class="arrow"
              type="ios-arrow-forward"
            />{{ data.title }}
          </p>
        </div>
      </div>
      <div class="auto-container">
        <!-- <h3 style="margin-top: 30px;">{{data.title}}</h3> -->
        <div
          id="contant"
          v-html="data.contents"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
import contant from "./index.js";
export default contant;
</script>

<style lang="less" scoped>
@import "./index.less";
</style>